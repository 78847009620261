var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row mb-4"
  }, [_c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_vm.logo ? _c('div', [_c('img', {
    staticStyle: {
      "background": "white"
    },
    attrs: {
      "src": _vm.logo,
      "alt": "",
      "width": "300"
    }
  })]) : _vm._e()])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("name")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.organization.name ? _vm.organization.name : "-") + " ")])]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("shortname")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.organization.short_name ? _vm.organization.short_name : "-") + " ")])]), _vm.organization_type != 'Awaris' ? _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("code")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.organization.code ? _vm.organization.code : "-") + " ")])]) : _vm._e(), _vm.organization_type != 'Individu' ? _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("account-type")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.organization.account_type ? _vm.organization.account_type : "-") + " ")])]) : _vm._e(), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("address")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.fullAddress ? _vm.fullAddress : "-") + " ")])]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_vm.organization_type == 'Individu' ? _c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("mobile-no")))]) : _vm._e(), _vm.organization_type != 'Individu' ? _c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("office-no")))]) : _vm._e(), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.organization.phone_number ? _vm.organization.phone_number : "-") + " ")])]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_vm.organization_type == 'Individu' ? _c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("email")))]) : _vm._e(), _vm.organization_type != 'Individu' ? _c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("office-email")))]) : _vm._e(), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.organization.email ? _vm.organization.email : "-") + " ")])]), _vm.organization_type != 'Individu' ? _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("website")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.organization.website ? _vm.organization.website : "-") + " ")])]) : _vm._e(), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("status")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm.organization.status == 1 ? _c('span', {
    staticClass: "badge badge-success"
  }, [_vm._v(_vm._s(_vm.$t("active")))]) : _vm._e(), _vm.organization.status != 1 ? _c('span', {
    staticClass: "badge badge-warning"
  }, [_vm._v(_vm._s(_vm.$t("inactive")))]) : _vm._e()])])]), _vm.organization_type != 'Awaris' ? _c('div', {
    staticClass: "row"
  }, [_vm.organization_type != 'Individu' ? _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("org.reg-no")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.organization.company_registration_number ? _vm.organization.company_registration_number : "-") + " ")])]) : _vm._e(), _vm.organization_type == 'Individu' ? _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("mykad")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.organization.mykad_number ? _vm.organization.mykad_number : "-") + " ")])]) : _vm._e(), _vm.organization_type != 'Individu' ? _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("org.founder")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.organization.founder_name ? _vm.organization.founder_name : "-") + " ")])]) : _vm._e(), _vm.organization_type != 'Individu' ? _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("pic-telno")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.organization.pic_phone_number ? _vm.organization.pic_phone_number : "-") + " ")])]) : _vm._e(), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("bank-account")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.organization.bank_account_number ? _vm.organization.bank_account_number : "-") + " ")])]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("acc-holder")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.organization.bank_account_holder ? _vm.organization.bank_account_holder : "-") + " ")])]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("bankname")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.organization.bank_name ? _vm.organization.bank_name : "-") + " ")])])]) : _vm._e(), _c('hr'), _vm.userRole == 'Staff' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("org.online")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm.organization.allow_payment_gateway == 1 ? _c('span', {
    staticClass: "badge badge-success"
  }, [_vm._v(_vm._s(_vm.$t("active")))]) : _vm._e(), _vm.organization.allow_payment_gateway != 1 ? _c('span', {
    staticClass: "badge badge-warning"
  }, [_vm._v(_vm._s(_vm.$t("inactive")))]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("org.cash")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm.organization.allow_cash_payment == 1 ? _c('span', {
    staticClass: "badge badge-success"
  }, [_vm._v(_vm._s(_vm.$t("active")))]) : _vm._e(), _vm.organization.allow_cash_payment != 1 ? _c('span', {
    staticClass: "badge badge-warning"
  }, [_vm._v(_vm._s(_vm.$t("inactive")))]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("accept-direct-payment")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm.organization.allow_direct_payment == 1 ? _c('span', {
    staticClass: "badge badge-success"
  }, [_vm._v(_vm._s(_vm.$t("active")))]) : _vm._e(), _vm.organization.allow_direct_payment != 1 ? _c('span', {
    staticClass: "badge badge-warning"
  }, [_vm._v(_vm._s(_vm.$t("inactive")))]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("accept-atb")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm.organization.allow_atb_payment == 1 ? _c('span', {
    staticClass: "badge badge-success"
  }, [_vm._v(_vm._s(_vm.$t("active")))]) : _vm._e(), _vm.organization.allow_atb_payment != 1 ? _c('span', {
    staticClass: "badge badge-warning"
  }, [_vm._v(_vm._s(_vm.$t("inactive")))]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("accept-funding")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm.organization.allow_loan_payment == 1 ? _c('span', {
    staticClass: "badge badge-success"
  }, [_vm._v(_vm._s(_vm.$t("active")))]) : _vm._e(), _vm.organization.allow_loan_payment != 1 ? _c('span', {
    staticClass: "badge badge-warning"
  }, [_vm._v(_vm._s(_vm.$t("inactive")))]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-12 text-right"
  }, [['Staff', 'IT'].includes(_vm.userRole) ? _c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'organization.edit',
        params: {
          organization_id: _vm.organizationId
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("update")))]) : _vm._e()], 1)]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }